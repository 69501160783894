import "./index.scss";

import * as Sentry from "@sentry/react";

import App from "./App";
import { ChatContextProvider } from "./context/ChatContext";
import { E3KitContextProvider } from "./context/E3KitContext";
import ReactDOM from "react-dom/client";
import { ThemeContextProvider } from "./context";
import { UnreadProvider } from "./context/UnreadContext";
import { ViewProvider } from "./context/ViewContext";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://be6bc4ec621007561e36f2be3a41685a@o4506491541061632.ingest.sentry.io/4506491542962176",
    // integrations: [new Sentry.BrowserTracing()],
    integrations: [
      new Sentry.BrowserTracing(),
      //   {
      //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //   tracePropagationTargets: [
      //     "localhost",
      //     /^https:\/\/conversation\.hodo\.app/,
      //   ],
      // }
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const urlParams = new URLSearchParams(window.location.search);
const targetOrigin =
  urlParams.get("target_origin") ||
  (process.env.REACT_APP_TARGET_ORIGIN as string);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);

// @ts-ignore
root.render(
  // <React.StrictMode>
  <ChatContextProvider>
    <E3KitContextProvider>
      <ViewProvider>
        <UnreadProvider>
          <ThemeContextProvider targetOrigin={targetOrigin}>
            <App />
          </ThemeContextProvider>
        </UnreadProvider>
      </ViewProvider>
    </E3KitContextProvider>
  </ChatContextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
