import api from "./api";

const goMe = () => {
  return api.get("/go/core/auth/doctor/me");
};

const getStreamGenerationToken = () => {
  return api.get(`/go/getStream/generateToken`);
};

const getVirgilGenerationToken = async (data) => {
  return api.get(
    `${process.env.REACT_APP_URL_VIRGIL_API}/api/go/virgilSecurity/generateToken`
  );
};

const registerEncryption = () => {
  return api.post(`go/getStream/registerEncryption`)
}

const createNewChannel = (data) => {
  return api.post(`go/getStream/createChannel`, data)
}

const deleteChannel = (data) => {
  return api.post(`go/getStream/deleteChannel`, data)
}

const getChannel = (id) => {
  return api.get(`go/getStream/channel/${id}`)
}

// eslint-disable-next-line import/no-anonymous-default-export
export { getStreamGenerationToken, getVirgilGenerationToken, goMe };
