import React, { PropsWithChildren, useContext, useEffect } from "react";
import {
  ChatActionType,
  IInitialChatState,
  useChatContext,
} from "./ChatContext";

import { EThree } from "@virgilsecurity/e3kit-browser";
import { getVirgilGenerationToken } from "../services";

type CreateGroupProps = {
  groupId: string;
  participantIds: string[];
  ownerId: string;
};

type GetGroupCardDataProps = {
  groupId: string;
  ownerId: string;
};

type GroupEncryptProps = {
  text: string | undefined;
  groupId: string;
  ownerId: string;
  groupCard: any;
};

type GroupDecryptProps = {
  text: string | undefined;
  groupId: string;
  senderCards: any;
  ownerId: string;
  groupCard: any;
};

type UpdateGroupCardProps = {
  groupId?: string | undefined;
  ownerId?: string | undefined;
};

type E3KitContextValue = {
  cleanup: () => Promise<void>;
  backupPrivateKey: () => Promise<void>;
  resetPrivateKeyBackup: () => Promise<void>;
  rotatePrivateKey: () => Promise<void>;
  restorePrivateKey: () => Promise<void>;
  register: () => Promise<void>;
  unregister: () => Promise<void>;
  hasLocalPrivateKey: () => Promise<any>;
  findUsers: (
    identities: (string | undefined) | (string | undefined)[]
  ) => Promise<any>;
  createGroup: ({
    groupId,
    participantIds,
    ownerId,
  }: CreateGroupProps) => Promise<any>;
  getGroup: (groupId: string) => Promise<any>;
  loadGroup: ({ groupId, ownerId }: GetGroupCardDataProps) => Promise<any>;
  getGroupCardData: ({
    groupId,
    ownerId,
  }: GetGroupCardDataProps) => Promise<any>;
  updateGroup: ({
    groupId,
    ownerId,
  }: UpdateGroupCardProps) => Promise<void>;
  deleteGroup: (groupId: string) => Promise<void>;
  groupEncrypt: ({
    text,
    groupId,
    ownerId,
    groupCard,
  }: GroupEncryptProps) => Promise<string>;
  groupDecrypt: ({
    text,
    groupId,
    senderCards,
    ownerId,
    groupCard,
  }: GroupDecryptProps) => Promise<string>;
};

const E3KitContext = React.createContext({} as E3KitContextValue);

export const E3KitContextProvider = ({ children }: PropsWithChildren) => {
  const { state, dispatch } = useChatContext();
  const { eThree, userToConnect } = state as IInitialChatState;
  const benchmarking = false;

  const getEThree = (e?: any) => {
    const e3kit = e || eThree || window.eThree;
    if (!e3kit) {
      throw new Error(`eThree not initialized`);
    }
    return e3kit;
  };

  const cleanup = async () => {
    const eThree = getEThree();
    try {
      await eThree.cleanup();
    } catch (err: any) {
      console.log(`Failed cleaning up: ${err}`);

      if (err.message?.includes("JWT is expired")) {
        await refreshE3KitToken();
        await cleanup();
      }
      throw new Error(err.message);
    }
  };

  const backupPrivateKey = async (
    password: string | undefined = process.env.REACT_APP_BACKUP_PRIVATE_KEY
  ) => {
    const eThree = getEThree();
    try {
      await eThree.backupPrivateKey(password);
    } catch (err: any) {
      console.log(`Failed backing up private key: ${err}`);

      if (err.message?.includes("JWT is expired")) {
        await refreshE3KitToken();
        await backupPrivateKey(password);
      }
    }
  };

  const resetPrivateKeyBackup = async () => {
    const eThree = getEThree();
    try {
      await eThree.resetPrivateKeyBackup();
    } catch (err: any) {
      console.log(`Failed resetting private key backup: ${err}`);

      if (err.message?.includes("JWT is expired")) {
        await refreshE3KitToken();
        await resetPrivateKeyBackup();
      }

      throw new Error(err.message);
    }
  };

  const rotatePrivateKey = async () => {
    const eThree = getEThree();
    try {
      await eThree.rotatePrivateKey();
      console.log(`Rotated private key instead: ${eThree.identity}`);
    } catch (err: any) {
      if (err.name === "PrivateKeyAlreadyExistsError") {
        await cleanup();
        await restorePrivateKey();
      } else {
        console.log(`Failed rotating private key: ${err}`);

        if (err.message?.includes("JWT is expired")) {
          await refreshE3KitToken();
          await rotatePrivateKey();
        }
      }
    }
  };

  const restorePrivateKey = async (
    password: string | undefined = process.env.REACT_APP_BACKUP_PRIVATE_KEY
  ) => {
    const eThree = getEThree();
    try {
      await eThree.restorePrivateKey(password);
      console.log(`Restored private key`);
    } catch (err: any) {
      if (err.name === "PrivateKeyNoBackupError") {
        console.log(err);
        await rotatePrivateKey();
        await backupPrivateKey(password);
      } else {
        console.log(`Failed restoring private key: ${err}`, err);

        if (err.message?.includes("JWT is expired")) {
          await refreshE3KitToken();
          await restorePrivateKey(password);
        }
      }
    }
  };

  const register = async () => {
    const eThree = getEThree();
    try {
      await cleanup();
      await eThree.register();
      await backupPrivateKey();
    } catch (err: any) {
      if (err.name === "IdentityAlreadyExistsError") {
        await restorePrivateKey();
      } else {
        console.log(`Failed registering: ${err}`);

        if (err.message?.includes("JWT is expired")) {
          await refreshE3KitToken();
          await register();
        }
      }
    }
  };

  const unregister = async () => {
    const eThree = getEThree();
    try {
      await cleanup();
      await eThree.unregister();
      console.log(`Unregistered`);
    } catch (err: any) {
      if (err.name === "RegisterRequiredError") {
        console.log(`Failed unregistering: ${err}`);

        if (err.message?.includes("JWT is expired")) {
          await refreshE3KitToken();
          await unregister();
        }
      }
    }
  };

  const hasLocalPrivateKey = async () => {
    const eThree = getEThree();
    const hasLocalPrivateKey = await eThree.hasLocalPrivateKey();
    return hasLocalPrivateKey;
  };

  const findUsers = async (
    identities: (string | undefined) | (string | undefined)[]
  ) => {
    if (!identities || !identities.length) return;

    const eThree = getEThree();
    let findUsersResult = null;

    try {
      findUsersResult = await eThree.findUsers(identities);
    } catch (err: any) {
      // console.log(
      //   `Failed looking up ${identities}'s cards with public keys: ${err}`
      // );
      console.log(`Lookup error ${identities}:`, err.lookupResult);

      if (err.message?.includes("JWT is expired")) {
        await refreshE3KitToken();
        await findUsers(identities);
      }
    }
    return findUsersResult;
  };

  const createGroup = async ({
    groupId,
    participantIds,
    ownerId,
  }: CreateGroupProps) => {
    const eThree = getEThree();
    let group = null;
    try {
      const participantCards = await findUsers(participantIds);
      group = await eThree.createGroup(groupId, participantCards);
      console.log(`Created group: ${groupId}`);
    } catch (err: any) {
      if (err.name === "GroupTicketAlreadyExistsError") {
        group = await getGroupCardData({ groupId, ownerId });
      } else {
        console.log(`Failed creating group: ${err}`);

        if (err.message?.includes("JWT is expired")) {
          await refreshE3KitToken();
          await createGroup({ groupId, participantIds, ownerId });
        }
      }
      throw new Error(err.message);
    }
    return group;
  };

  const getGroup = async (groupId: string) => {
    if (!groupId) return;
    const eThree = getEThree();
    let group = null;
    try {
      group = await eThree.getGroup(groupId);
    } catch (err: any) {
      console.log(`Failed getting group: ${err}`);

      if (err.message?.includes("JWT is expired")) {
        await refreshE3KitToken();
        await getGroup(groupId);
      }
    }
    return group;
  };

  const loadGroup = async ({ groupId, ownerId }: GetGroupCardDataProps) => {
    if (!groupId || !ownerId) return;
    const eThree = getEThree();
    let group = null;
    try {
      const initiatorCard = await findUsers(ownerId);
      if (!initiatorCard) return;
      group = await eThree.loadGroup(groupId, initiatorCard);
      // console.log(`Loaded group ${groupId}:`, group);
    } catch (err: any) {
      if (err.name === "MissingPrivateKeyError") {
        await restorePrivateKey();
      } else if (
        err.name === "GroupError" &&
        err.message === "Current user has no access to the group ticket"
      ) {
        console.log(err.message);
        // await deleteGroup(groupId);
        // return {
        //   reCreate: true,
        // };
      } else {
        console.log(`Failed loading group data: ${err}`);

        if (err.message?.includes("JWT is expired")) {
          await refreshE3KitToken();
          await loadGroup({ groupId, ownerId });
        }
      }
      throw new Error(err.message);
    }
    return group;
  };

  const updateGroup = async ({
    groupId,
    ownerId,
  }: UpdateGroupCardProps) => {
    try {
      let group = null;

      if (!group || group === null) {
        if (!groupId || !ownerId) return;
        group = await getGroupCardData({ groupId, ownerId });
      }
      group && (await group.update());
    } catch (err) {}
  };

  const getGroupCardData = async ({
    groupId,
    ownerId,
  }: GetGroupCardDataProps) => {
    if (!groupId || !ownerId) return;
    let group = null;
    try {
      group = await getGroup(groupId);

      if (!group || group === null) {
        group = await loadGroup({ groupId, ownerId });
      }
    } catch (err) {}
    return group;
  };

  const deleteGroup = async (groupId: string) => {
    const eThree = getEThree();
    try {
      await eThree.deleteGroup(groupId);
      console.log(`Deleted group: ${groupId}`);
    } catch (err: any) {
      console.log(`Failed deleting group: ${err}`);

      if (err.message?.includes("JWT is expired")) {
        await refreshE3KitToken();
        await deleteGroup(groupId);
      }

      throw new Error(err.message);
    }
  };

  const groupEncrypt = async ({
    text,
    groupId,
    ownerId,
    groupCard,
  }: GroupEncryptProps) => {
    if (!groupId || groupId === null) return;
    if (typeof text !== "string") return text;

    let encryptedText = null;
    let repetitions = benchmarking ? 100 : 1;
    let group = groupCard || null;

    try {
      if (!group) {
        group = await getGroupCardData({ groupId, ownerId });
      }

      if (!group) return encryptedText;

      for (let i = 0; i < repetitions; i++) {
        encryptedText = await group.encrypt(text);
      }
    } catch (err: any) {
      console.log(`Failed encrypting and signing: ${err}`);

      if (
        err.name === "GroupError" &&
        err.message?.includes("This group is out of date")
      ) {
        await updateGroup(group);
        encryptedText = await groupEncrypt({
          text,
          groupId,
          ownerId,
          groupCard,
        });
      }

      if (err.message?.includes("JWT is expired")) {
        await refreshE3KitToken();
        encryptedText = await groupEncrypt({
          text,
          groupId,
          ownerId,
          groupCard,
        });
      }

      throw new Error(err.message);
    }
    return encryptedText;
  };

  const groupDecrypt = async ({
    text,
    groupId,
    senderCards,
    ownerId,
    groupCard,
  }: GroupDecryptProps) => {
    if (!groupId || groupId === null) return;
    if (typeof text !== "string") return text;

    let decryptedText = null;
    let repetitions = benchmarking ? 100 : 1;
    let group = groupCard || null;

    try {
      if (!group) {
        group = await getGroupCardData({ groupId, ownerId });
      }

      if (!group || !senderCards) return decryptedText;

      for (let i = 0; i < repetitions; i++) {
        decryptedText = await group.decrypt(text, senderCards);
      }
    } catch (err: any) {
      console.log(err);

      if (
        err.name === "GroupError" &&
        err.message?.includes("This group is out of date")
      ) {
        await updateGroup(group);
        decryptedText = await groupDecrypt({
          text,
          groupId,
          senderCards,
          ownerId,
          groupCard,
        });
      }

      if (err.message?.includes("JWT is expired")) {
        await refreshE3KitToken();
        decryptedText = await groupDecrypt({
          text,
          groupId,
          senderCards,
          ownerId,
          groupCard,
        });
      }

      throw new Error(err.message);
    }

    return decryptedText;
  };

  const cleanUserE3Kit = async () => {
    await cleanup();
    // await unregister();
    // await resetPrivateKeyBackup();

    dispatch({
      type: ChatActionType.SET_IS_CHAT_RUNNING,
      payload: false,
    });
  };

  const runInitialize = async (repetitions: number) => {
    repetitions++;
    const hasLocalPrK = await hasLocalPrivateKey();

    if (!hasLocalPrK) {
      await register();
    }

    dispatch({
      type: ChatActionType.SET_IS_CHAT_RUNNING,
      payload: hasLocalPrK,
    });

    if (repetitions <= 1) {
      await runInitialize(repetitions);
    }
  };

  const initializeE3Kit = async (isRefresh: boolean = false) => {
    try {
      const getToken = async () => {
        const res = await getVirgilGenerationToken().then((data) =>
          data.json()
        );
        return res.token;
      };

      // if (!res || !res?.token) return;

      // dispatch({
      //   type: ChatActionType.SET_VIRGIL_TOKEN,
      //   payload: res?.token,
      // });

      const eThree = await EThree.initialize(getToken);
      window.eThree = eThree;

      dispatch({
        type: ChatActionType.SET_ETHREE,
        payload: eThree,
      });

      if (isRefresh) return;

      await cleanUserE3Kit();

      let repetitions = 0;
      await runInitialize(repetitions);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshE3KitToken = async () => {
    await initializeE3Kit(true);
  };

  useEffect(() => {
    if (!userToConnect?.id) return;
    (async () => initializeE3Kit())();
  }, [userToConnect.id]);

  const contextValue: E3KitContextValue = {
    cleanup,
    backupPrivateKey,
    resetPrivateKeyBackup,
    rotatePrivateKey,
    restorePrivateKey,
    register,
    unregister,
    hasLocalPrivateKey,
    findUsers,
    createGroup,
    getGroup,
    loadGroup,
    getGroupCardData,
    updateGroup,
    deleteGroup,
    groupEncrypt,
    groupDecrypt,
  };

  return (
    <E3KitContext.Provider value={contextValue}>
      {children}
    </E3KitContext.Provider>
  );
};

export const useE3KitContext = () => useContext(E3KitContext);
