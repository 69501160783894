import React, { PropsWithChildren, useContext, useState } from "react";

export type ChatInfoItem =
  | "Pinned Messages"
  | "Photos & Videos"
  | "Files"
  | "Shared Groups";

type ViewContextValue = {
  chatInfoItem?: ChatInfoItem;
  isChatInfoOpen: boolean;
  isListMentions: boolean;
  isSideDrawerOpen: boolean;
  isNewChat: boolean;
  participantCards: any;
  groupCard: any;
  groupId: string;
  setChatInfoItem: React.Dispatch<
    React.SetStateAction<ChatInfoItem | undefined>
  >;
  setChatInfoOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setListMentions: React.Dispatch<React.SetStateAction<boolean>>;
  setNewChat: React.Dispatch<React.SetStateAction<boolean>>;
  setSideDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setParticipantCards: React.Dispatch<React.SetStateAction<any>>;
  setGroupCard: React.Dispatch<React.SetStateAction<any>>;
  setGroupId: React.Dispatch<React.SetStateAction<string>>;
};

const ViewContext = React.createContext({} as ViewContextValue);

export const ViewProvider = ({ children }: PropsWithChildren) => {
  const [chatInfoItem, setChatInfoItem] = useState<ChatInfoItem>();
  const [isChatInfoOpen, setChatInfoOpen] = useState(false);
  const [isListMentions, setListMentions] = useState(false);
  const [isSideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [isNewChat, setNewChat] = useState(false);
  const [participantCards, setParticipantCards] = useState(false);
  const [groupCard, setGroupCard] = useState(false);
  const [groupId, setGroupId] = useState("");

  const contextValue: ViewContextValue = {
    chatInfoItem,
    isChatInfoOpen,
    isListMentions,
    isSideDrawerOpen,
    isNewChat,
    participantCards,
    groupCard,
    groupId,
    setChatInfoItem,
    setChatInfoOpen,
    setListMentions,
    setNewChat,
    setSideDrawerOpen,
    setParticipantCards,
    setGroupCard,
    setGroupId,
  };

  return (
    <ViewContext.Provider value={contextValue}>{children}</ViewContext.Provider>
  );
};

export const useViewContext = () => useContext(ViewContext);
