import { useCallback, useEffect, useState } from 'react';

import { useWindowSize } from './useWindowSize';

/**
 * Internal, handles the mobile/responsive view adjustments.
 */
export const useMobileView = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    const mobileChannelList = document.querySelector('#mobile-channel-list');

    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add('show');
      document.body.style.overflow = 'hidden';
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove('show');
      document.body.style.overflow = 'auto';
    }
  }, [isMobileNavVisible]);

  useEffect(() => {
    if (!windowSize.width) return;
    setIsMobile(windowSize.width <= 640);
  }, [windowSize]);

  const toggleMobileNav = useCallback(() => {
    if (isMobile) {
      setIsMobileNavVisible((prevState) => !prevState);
    }
  }, [isMobile]);

  return { isMobile, toggleMobileNav };
};
