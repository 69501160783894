import "stream-chat-react/dist/css/v2/index.css";
import "./styles/index.css";

import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { LoadingIndicator } from "stream-chat-react";

const ChatPage = React.lazy(() => import("./pages/Chat"));
const SignIn = React.lazy(() => import("./pages/SignIn"));

const apiKey = process.env.REACT_APP_STREAM_KEY;
const urlParams = new URLSearchParams(window.location.search);
const targetOrigin =
  urlParams.get("target_origin") ||
  (process.env.REACT_APP_TARGET_ORIGIN as string);

const ErrorPage = () => {
  return (
    <div className="hodo-chat-loading">
      <div>URL Invalid</div>
    </div>
  );
};

const LoadingPage = () => {
  return (
    <div className="hodo-chat-loading">
      <LoadingIndicator size={52} />
    </div>
  );
};

const RouteList = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<ChatPage apiKey={apiKey!} targetOrigin={targetOrigin!} />}
      />
      <Route
        path="sign-in"
        element={
          // <Suspense fallback={<ChatSetupProcessing />}>
          // </Suspense>
            <SignIn />
        }
      />
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
};

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<RouteList />} />
      </Routes>
    </Router>
  );
};

const App = () => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <AppRoutes />
    </Suspense>
  );
};

export default App;
