const getUrlPrefix = (url) => {
  if (url.includes("https://") || url.includes("http://")) return url;
  const endPoint = process.env.REACT_APP_URL_API.replace("/api/go", "");
  return `${endPoint + "/api"}${url}`;
};

const getToken = () => {
  const userToken = localStorage.getItem("userToken");
  return `Bearer ${userToken}`;
};

const _errorHandler = (reject, err, showMessage = true) => {
  try {
    if (err.response) {
      if (err.response?.status === 401 || err.response?.status === 406) {
        // appUtils.removeAll();
      } else if (err.response?.data.status === 414 && showMessage) {
        let firstKey = Object.keys(err.response?.data?.message)[0];
        if (firstKey) {
          console.log(err.response?.data?.message[firstKey][0]);
          return reject({
            message: err.response?.data?.message[firstKey][0],
          });
        }
      } else if (err.response?.status === 422 && showMessage) {
        let messages = Object.values(err.response?.data.errors).map(
          (errs) => errs[0]
        );
        console.log(messages[0]);
        return reject({
          message: messages[0],
        });
      } else if (
        (err.response?.status === 500 || err.response?.code === 500) &&
        showMessage
      ) {
        console.log(err);
        return reject(err);
      } else if (err.response?.status === 409) {
        const error = err.response?.data["Error!"];
        console.log(error);
        return reject(error);
      } else if (err.response?.data?.message && showMessage) {
        console.log(err.response?.data?.message);
        return reject({
          message: err.response?.data?.message,
        });
      } else {
        console.log(err);
      }
    }

    reject(err);
  } catch (error) {
    console.log(error);
  }
};

const get = (url, params, configs = null) => {
  return new Promise((resolve, reject) => {
    window
      .fetch(getUrlPrefix(url), {
        params,
        ...configs,
        headers: {
          ...configs?.headers,
          Authorization: configs?.headers?.Authorization || getToken(),
        },
      })
      .then((response) => {
        if (response?.data?.code === 200) {
          resolve(response?.data);
          return;
        }
        if (response?.status === 200 || response?.status === 201) {
          resolve(response);
        }
      })
      .catch((error) => {
        _errorHandler(reject, error);
      });
  });
};

const post = (url, data = {}, headers = null) => {
  return new Promise((resolve, reject) => {
    let options = {};
    if (headers) {
      options.headers = headers;
    }

    window
      .fetch(getUrlPrefix(url), {
        body: JSON.stringify(data),
        method: "POST",
        ...options,
        headers: {
          ...options?.headers,
          Authorization: options?.headers?.Authorization || getToken(),
        },
      })
      .then((response) => {
        if (response?.data?.message) {
          console.log(response?.data?.message);
        }
        if (response?.data?.code === 200) {
          resolve(response?.data);
          return;
        }
        if (response?.status === 200 || response?.status === 201) {
          resolve(response);
          return;
        }
      })
      .catch((error) => {
        _errorHandler(reject, error);
      });
  });
};

const put = (url, data = {}, headers = null) => {
  return new Promise((resolve, reject) => {
    let options = {};
    if (headers) {
      options.headers = headers;
    }
    window
      .fetch(getUrlPrefix(url), {
        body: JSON.stringify(data),
        method: "PUT",
        ...options,
        headers: {
          ...options?.headers,
          Authorization: options?.headers?.Authorization || getToken(),
        },
      })
      .then((response) => {
        if (response?.data?.message) {
          console.log(response?.data?.message);
        }
        if (response?.data?.code === 200) {
          resolve(response?.data);
          return;
        }
        if (response?.status === 200 || response?.status === 201) {
          resolve(response);
          return;
        }
      })
      .catch((error) => {
        _errorHandler(reject, error);
      });
  });
};

const deleteFetch = (url, data = {}, headers = null) => {
  return new Promise((resolve, reject) => {
    let options = {};
    if (headers) {
      options.headers = headers;
    }
    window
      .fetch(getUrlPrefix(url), {
        body: JSON.stringify(data),
        method: "DELETE",
        ...options,
        headers: {
          ...options?.headers,
          Authorization: options?.headers?.Authorization || getToken(),
        },
      })
      .then((response) => {
        if (response?.data?.message) {
          console.log(response?.data?.message);
        }
        if (response?.data?.code === 200) {
          resolve(response?.data);
          return;
        }
        if (response?.status === 200 || response?.status === 201) {
          resolve(response);
          return;
        }
      })
      .catch((error) => {
        _errorHandler(reject, error);
      });
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get,
  post,
  put,
  deleteFetch,
};
