import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { OwnUserResponse, UserResponse } from "stream-chat";
import { getStreamGenerationToken, goMe } from "../services";

import { StreamChatGenerics } from "../types";

declare global {
  interface Window {
    eThree: any;
  }
}

export interface IInitialChatState {
  userToConnect: OwnUserResponse<StreamChatGenerics> | UserResponse<StreamChatGenerics> & {
    image?: string | undefined
  };
  userChatToken: string;
  virgilToken: string;
  eThree: any;
  isChatRunning: boolean;
}

export const initialChatState: IInitialChatState = {
  userToConnect: {
    id: "",
  },
  userChatToken: "",
  virgilToken: "",
  eThree: null,
  isChatRunning: false,
};

export interface IChatContext {
  state: IInitialChatState;
  dispatch: React.Dispatch<IChatAction>;
}

export enum ChatActionType {
  AUTO_SIGNIN = "AUTO_SIGNIN",
  SET_USER_CHAT_TOKEN = "SET_USER_CHAT_TOKEN",
  SET_VIRGIL_TOKEN = "SET_VIRGIL_TOKEN",
  SET_ETHREE = "SET_ETHREE",
  SET_IS_CHAT_RUNNING = "SET_IS_CHAT_RUNNING",
}

export type IChatAction = {
  type: ChatActionType;
  payload?: any;
};

/**
 * ChatReducer
 * @param state IInitialChatState
 * @param action IChatAction
 * @returns state
 */
const ChatReducer = (
  state: IInitialChatState,
  action: IChatAction
): typeof initialChatState => {
  const { type, payload } = action;

  switch (type) {
    case ChatActionType.AUTO_SIGNIN:
      return {
        ...state,
        userToConnect: payload,
      };
    case ChatActionType.SET_USER_CHAT_TOKEN:
      return {
        ...state,
        userChatToken: payload,
      };
    case ChatActionType.SET_VIRGIL_TOKEN:
      return {
        ...state,
        virgilToken: payload,
      };
    case ChatActionType.SET_ETHREE:
      return {
        ...state,
        eThree: payload,
      };
    case ChatActionType.SET_IS_CHAT_RUNNING:
      return {
        ...state,
        isChatRunning: payload,
      };
    default:
      throw new Error();
  }
};

/**
 * Actions
 */
export const getStreamToken = (dispatch: Dispatch<IChatAction>) => async () => {
  try {
    const res = await getStreamGenerationToken().then((data) => data.json());
    if (!res) return;
    dispatch({
      type: ChatActionType.SET_USER_CHAT_TOKEN,
      payload: res?.token,
    });
  } catch (error) {
    console.log(error);
  }
};

export const autoSignIn =
  (dispatch: Dispatch<IChatAction>) =>
  async (token: string | null): Promise<void> => {
    if (!token) return;
    try {
      const res = await goMe().then((data) => data.json());
      if (res?.data) {
        const user = res?.data;

        localStorage.setItem("user", JSON.stringify({ ...user }));
        dispatch({
          type: ChatActionType.AUTO_SIGNIN,
          payload: {...user, image: user?.avatar},
        });

        getStreamToken(dispatch)();
      }
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  };

const ChatContext = createContext<IChatContext>({
  state: initialChatState,
  dispatch: () => {},
});

export const useChatContext = () => {
  const contextValue = useContext(ChatContext);
  if (!contextValue)
    throw new Error("useChatContext must be used within a ChatProvider");
  return contextValue as IChatContext;
};

export const ChatContextProvider = ({
  children,
}: PropsWithChildren<{ value?: IChatContext }>) => {
  const [state, dispatch] = useReducer(
    ChatReducer,
    initialChatState as IInitialChatState
  );

  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    if (!userToken) return;
    autoSignIn(dispatch)(userToken);
  }, [userToken]);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
