import { useCallback, useLayoutEffect, useState } from 'react';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
  const [isResizing, setIsResizing] = useState(false);
  const [debounce, setDebounce] = useState();

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const debounceEffect = useCallback(() => {
    if (isResizing) return;

    setIsResizing(true);
    clearTimeout(debounce);
    const timeout: any = setTimeout(() => {
      setIsResizing(false);
      handleSize();
    }, 300);
    setDebounce(timeout);

    return () => clearTimeout(timeout);
  }, [isResizing, debounce]);

  useLayoutEffect(() => {
    window.addEventListener('resize', debounceEffect);

    return () => window.removeEventListener('resize', debounceEffect);
  }, [debounceEffect]);

  return windowSize;
};
